class Home {
  constructor() {
    this.dom = {};

    this.data = {};

    this.components = {};

    this.mount();
  }

  // eslint-disable-next-line class-methods-use-this
  mount() {}
}

if (document.getElementById('page-home')) {
  // eslint-disable-next-line no-new
  new Home();
}
