/* eslint-disable import/no-import-module-exports */
import './styles/index.scss';

// vendor
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/collapse';

// views
import './js/views';

// load all icons
(function loadIcons(r) {
  r.keys().forEach(r);
}(require.context('./img/icons', true, /\.svg$/)));

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) module.hot.accept();
