// components
import Validation from '@/js/components/forms/validation';

class OfferOverview {
  constructor() {
    this.dom = {
      form: document.getElementById('form-offer-order'),
      alertScroll: document.querySelector('.js-alert-scroll:not(.d-none)'),
    };

    this.components = {
      formValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.form) {
      this.components.formValidation = new Validation(this.dom.form);
    }

    if (this.dom.alertScroll) {
      this.dom.alertScroll.scrollIntoView({ behavior: 'smooth' });
    }
  }
}

if (document.getElementById('page-offer')) {
  // eslint-disable-next-line no-new
  new OfferOverview();
}
