import VanillaTilt from 'vanilla-tilt';

document.querySelectorAll('.js-tilt').forEach((el) => {
  VanillaTilt.init(el);
});

function requireAll(r) {
  r.keys().forEach(r);
}

// load all js files, but exclude those in partials
requireAll(require.context('./', true, /^(?!.*\/partials\/).*.js$/));
