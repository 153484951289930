export default class Dropdown {
  constructor(dropdownElement) {
    this.dom = {
      dropdownElement,
      links: [...dropdownElement.querySelectorAll('.nav-link[data-target-id]')],
      dropdowns: [...dropdownElement.querySelectorAll('.l-dropdown-menu')],
    };

    this.props = {
      timerIn: null,
      timerOut: null,
      waitOut: 0,
      waitIn: 200,
      breakpoint: 991,
      mq: null,
    };

    this.events = {
      onMouseLinkEnter: this.onMouseLinkEnter.bind(this),
      onMouseLinkLeave: this.onMouseLinkLeave.bind(this),
      onMouseDropdownEnter: this.onMouseDropdownEnter.bind(this),
      onMouseDropdownLeave: this.onMouseDropdownLeave.bind(this),
      bindEvents: this.bindEvents.bind(this),
    };

    this.mount();
  }

  mount() {
    this.props.mq = window.matchMedia(`(min-width: ${this.props.breakpoint}px)`);
    this.events.bindEvents();
    this.props.mq.addEventListener('change', this.events.bindEvents);
  }

  bindEvents() {
    if (this.props.mq.matches) {
      this.dom.links.forEach((link) => {
        link.addEventListener('mouseenter', this.events.onMouseLinkEnter);
        link.addEventListener('mouseleave', this.events.onMouseLinkLeave);
      });
      this.dom.dropdowns.forEach((dropdown) => {
        dropdown.addEventListener('mouseenter', this.events.onMouseDropdownEnter);
        dropdown.addEventListener('mouseleave', this.events.onMouseDropdownLeave);
      });
    } else {
      this.dom.links.forEach((link) => {
        link.removeEventListener('mouseenter', this.events.onMouseLinkEnter);
        link.removeEventListener('mouseleave', this.events.onMouseLinkLeave);
      });
      this.dom.dropdowns.forEach((dropdown) => {
        dropdown.removeEventListener('mouseenter', this.events.onMouseDropdownEnter);
        dropdown.removeEventListener('mouseleave', this.events.onMouseDropdownLeave);
      });
    }
  }

  onMouseLinkEnter(e) {
    // eslint-disable-next-line func-names
    this.props.timerIn = setTimeout(() => {
      this.setAriaExpanded(e.target, true);
    }, this.props.waitIn);
  }

  onMouseLinkLeave(e) {
    if (this.props.timerIn) clearTimeout(this.props.timerIn);
    this.setAriaExpanded(e.target, false);
  }

  onMouseDropdownEnter(e) {
    const link = this.getLinkById(e.target.id);
    this.setAriaExpanded(link, true);
    if (this.props.timerOut) {
      clearTimeout(this.props.timerOut);
    }
  }

  onMouseDropdownLeave(e) {
    const evt = e;

    if (this.props.timerIn) {
      clearTimeout(this.props.timerIn);
    }

    this.props.timerOut = setTimeout(() => {
      this.close(evt);
    }, this.props.waitOut);
  }

  close(e) {
    const link = this.getLinkById(e.target.id);
    this.setAriaExpanded(link, false);
    this.props.timerIn = null;
    this.props.timerOut = null;
  }

  // eslint-disable-next-line class-methods-use-this
  setAriaExpanded(el, state) {
    el.setAttribute('aria-expanded', state);
  }

  getLinkById(id) {
    return this.dom.links.find((link) => link.dataset.targetId === id);
  }
}
